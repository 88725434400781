<template>
  <div id="proveedor">
    <vx-card class="mb-6" >
      <div class="vx-row">
        <div class="vx-col w-full">
            <h1 class="rg-big-title extrabold rg-title">Mis <span class="orange">proyectos archivados</span>.</h1>
        </div>
      </div>
    </vx-card>

    <vx-card>
      <template v-if="projectsCount > 0">
        <div class="vx-row">
          <div class="vx-col w-full" >
            <p>
              Aquí podrás ver los detalles de los proyectos que han sido archivados.
              Si deseas activar un proyecto de nuevo, da click en el botón correspondiente.            
            </p>
          </div> 
        </div>
      </template>
      <template v-else >
        <h2>No tienes proyectos archivados</h2>
        <p>Podrás regresar cuando lo desees, para administrar los proyectos archivados.</p>
      </template>

      <div class="vx-row mb-6 mt-4">        
        <div class="vx-col w-1/2">
          <vs-button color="success" type="border" icon-pack="feather" icon="icon-briefcase" @click="goToActiveProjects">Ver proyectos activos</vs-button>
        </div>
      </div>

      <archived-projects-list @loadedProjects="loadedProjects" ></archived-projects-list>
    </vx-card>
  </div>
</template>

<script>
import ArchivedProjectsList from '../../../components/supplier/projects/ArchivedProjectsList.vue';
export default {
  components: {
    ArchivedProjectsList
  },
  data() {
    return {
      projectsCount: 0
    }
  },
  methods: {
    loadedProjects(count) {
      this.projectsCount = count;
    },
    goToActiveProjects(){
      this.$router.replace({ name: 'misProyectos'});
    }
  }
}
</script>