<template>

  <div class="data-list-container data-list-list-view data-list-high-rows vs-con-loading__container" id="main-div" > 
    <vs-table ref="table" v-model="projectSelected"  @selected="handleSelected"  pagination :max-items="itemsPerPage" search :data="projects" noDataText="No hay datos disponibles">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container fix-table-select">      
          <!-- <vx-select style="min-width:300px" multiple v-model="filtersSelected" placeholder="Seleccione para filtrar">
            <vx-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in filterOptions"  />
          </vx-select> -->
          <v-select
            name="filter"
            label="label"
            class="vs-custom mr-4"
            style="min-width:300px"
            v-model="filtersSelected"
            :options="filterOptions"
            :reduce="label => label.id"
            :clearable="false"
            :searchable="false"
            placeholder="Seleccione para filtrar"
            multiple
          >
          </v-select>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="fix-dropdown border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ projects.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : projects.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="itemsPerPage=it" :key="it">
              <span>{{it}}</span>
            </vs-dropdown-item>            
          </vs-dropdown-menu>
        </vs-dropdown>

      </div>

      <template slot="thead">
        <vs-th v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
          <vs-td>
            {{ tr.id }}
          </vs-td>
          <vs-td>
            {{ tr.alias }}
          </vs-td>
          <vs-td>
            {{ tr.status }}
          </vs-td>
          <vs-td>
            {{ costFormat(valOrPending(tr.cost)) }}
          </vs-td>
          <vs-td>
            {{ prcgFormat(valOrPending(tr.tasa, 2)) }}
          </vs-td>
          <vs-td>
            {{ tr.last_resolution }}
          </vs-td>
          <vs-td>
            {{ resolutionDateStr(tr.resolution_date) }}
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <vx-tooltip text="Desarchivar el proyecto" position="left" v-if="canUnarchiveProject(tr)" >
              <feather-icon icon="FolderPlusIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="unarchiveProjectRequest(tr)" />
            </vx-tooltip>
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import formatHelper from '../../mixins/formatHelper';
import dateHelper from '../../mixins/dateHelper';

const columnDefinitions = [
  { title: "ID", key: "id", sortable: true },
  { title: "Alias", key: "alias", sortable: true },
  { title: "Estado", key: "status", sortable: true },
  { title: "Factura", key: "cost", sortable: true },
  { title: "Tasa", key: "tasa", sortable: true },
  { title: "Razón de Archivado", key: "resolution_client_cause", sortable: true },
  { title: "Fecha Archivado", key: "resolution_date", sortable: true },
  { title: "Acciones", key: "delete", sortable: false}
];

const filterDefinitions = [
  { id: 2, label: "En revisión" },
  { id: 3, label: "Pre aprobados" },
  { id: 4, label: "Aprobados" },
  { id: 5, label: "En fondeo" },
  { id: 6, label: "Firma de contratos" },
  { id: 7, label: "En instalación" },
  { id: 8, label: "Entregados" },
  { id: 9, label: "Rechazados" },
  { id: 10, label: "Reembolsados" },
];

const itemsPerPageDefinitions = [5,10,25,50];

const resolutionCauseDefinitions = [
  { id: 0, label: "No definido" },
  { id: 1, label: "El cliente optó por otro crédito" },
  { id: 2, label: "El cliente lo pagó de contado" },
  { id: 3, label: "El cliente optó por no realizar el proyecto" },
  { id: 4, label: "Otra razón" },
];

const resolutionSupplierCauseDefinitions = [
  { id: 0, label: "No definido" },
  { id: 1, label: "Seguimiento inadecuado del proveedor" },
  { id: 2, label: "Otra razón" },
  { id: 3, label: "No se obtuvo la documentación" },
];

export default {
  name: "archived-projects-list",
  props: [],
  mixins: [formatHelper, dateHelper],
  components: {
  },
  mounted() {
    this.loadProjects();
  },
  data() {
    return {
      projects: [],
      allProjects : [],
      orderKey: "",
      projectIdToDelete: -1,
      columns: columnDefinitions,
      filtersSelected: [],
      filterOptions: filterDefinitions,
      itemsPerPageDefinitions: itemsPerPageDefinitions,
      itemsPerPage: itemsPerPageDefinitions[1],
      isMounted: false,
      loading: false,
      showUnarchivePrompt: false,
      unArchivingProject: null,
      projectSelected: null
    };
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.projects.length
    },
    unarchivePromptTitle(){
      return "Archivar proyecto " + this.unArchivingProject; 
    },    
  },
  watch: {
    filtersSelected: function() {
      if(this.filtersSelected.length < 1){
        this.projects = this.allProjects;
      }
      else {
        
        this.projects = this.allProjects.filter(item => this.filtersSelected.includes(item.step));
      }
    },
    loading: function(){
      if(this.loading) {
        this.$vs.loading({
          container: '#main-div',
          scale: 1
        })
      }
      else {
        this.$vs.loading.close('#main-div > .con-vs-loading')
      }
    },
  },
  methods: {
    loadProjects(){
      this.isMounted = false;
      this.loading = true;

      axios
      .get("/api/v1/supplier/archivedProjectsList")
      .then(response => {
        this.projects = this.allProjects = response.data;
        this.$emit("loadedProjects", this.allProjects.length);
      })
      .catch()
      .finally(
        this.isMounted = true,
        this.loading = false
      );
    },
    unarchiveProjectRequest (proj) {
      this.unArchivingProject = proj.id;
      let mssg = "¿Realmente desea desarchivar el proyecto " + proj.id + "?";

      if(proj.step != 4){
        mssg += " El proyecto se desarchivará con el estado que tenía.";
      }
      else {
        // RNs 
        // si tenia menos de 30 dias aprobado, se desarchiva como aprobado,
        // si tenia mas de 30 dias aprobado, se desarchiva como pre-aprobado
        if(proj.approved_days <= 30){
          mssg += " El proyecto se desarchivará en estado aprobado.";
        }
        else {
          mssg += " El proyecto tenía más de 30 días de aprobado, por lo que se desarchivará pero volverá a estado pre-aprobado.";
        }
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Desarchivar proyecto',
        text: mssg,
        acceptText: "Desarchivar",
        cancelText: 'Cancelar',
        accept: this.doUnarchiveProject
      });
    },
    doUnarchiveProject(){
      this.loading = true;
      let obj = { project: this.unArchivingProject };
      axios.post('/api/v1/supplier/unarchiveProject', obj)
      .then(response => {
        this.loadProjects();
        this.$vs.notify({
          color: 'success',
          title: 'Proyecto desarchivado',
          text: 'El proyecto ha sido desarchivado exitosamente'
        })
      })
      .catch(err => {
         this.$vs.notify({
          color: 'danger',
          title: 'Error',
          text: 'Ha ocurrido un error en la operación, intente más tarde'
        }),
        this.loading = false
      })
    },
    closeUnarchivePrompt() {
      this.showUnarchivePrompt = false
    },
    async handleSelected(proj){
      await this.timeout(300);
      this.showProject(proj.id, proj.alias);
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    showProject(id, alias){
      this.$router.push({ name: 'detalleProyecto', params: { id: id, alias: alias } })
    },
    plazoStr(value) {
      if (!value) return "";
      if (value == "Pendiente") return value;
      else return value + " meses";
    },
    valOrPending(value){
      return value == 0 ? 'Pendiente' : value;
    },
    resolutionDateStr(value) {
      if (value !== null) return this.regularDateFormat(value);
      else return "-";
    },
    canUnarchiveProject(proj) {
      // Se pueden desarchivar solo los proyectos que archivo el proveedor
      return proj.resolution_client_cause != 0;      
    }   
  }
};
</script>
